// .toolBar {
    
// }

.loadingButton {
    width: 65.81px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left_container {
    button {
        margin-left: -8px;
        img {
            width: 32px;
            height: 32px;
        }
    }
}

.right_container {
    display: flex;
    flex-grow: 1;
    align-items: center;


    .navigation_button_groups {
        flex: 1;

        .textButton {
            padding: 6px 8px;

            p {
                text-transform: capitalize;
            }
        }
    }

    .log_in_out_button {
        align-self: flex-end;
    }
}