@import 'global.scss';

.waitingContainer {
    height: $full_height_with_appbar;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.login_container {
    margin-top: 16px;
    height: $full_height_with_appbar;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 300px;
        padding: 16px 0;

    }
}